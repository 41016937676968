<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>
      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="移民群众生产生活条件变化情况"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row v-if="type == 'add'">
          <div class="leftTitletStyle">操作</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">行政区域</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">年度</div>
        </el-row>

        <!-- 后扶移民涉及工程和行政区域情况 -->
        <el-row>
          <el-col :span="4" class="title_view3">
            <div class="firstTitletStyle">
              <div>
                后扶移民涉及工程和行政区域情况
              </div>
            </div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">纳入后扶范围大中型水库</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">枢纽工程所在地（座）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">仅库区所在地（座）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <div class="leftTitletStyle">移民乡（镇）（个）</div>
            </el-row>

            <el-row>
              <div class="leftTitletStyle">移民村（个）</div>
            </el-row>

            <el-row>
              <div class="leftTitletStyle">移民组（个）</div>
            </el-row>
          </el-col>
        </el-row>

        <!-- 后扶移民基本情况 -->
        <el-row>
          <el-col :span="4" class="title_view1">
            <div class="firstTitletStyle">后扶移民基本情况</div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <div class="leftTitletStyle">后期扶持人数（人）</div>
            </el-row>

            <el-row>
              <div class="leftTitletStyle">后期扶持兑现人数（人）</div>
            </el-row>

            <el-row>
              <div class="leftTitletStyle">移民人均耕地（亩）</div>
            </el-row>

            <el-row>
              <div class="leftTitletStyle">移民人均住房面积（㎡）</div>
            </el-row>

            <el-row>
              <div class="leftTitletStyle">移民人均可支配收入（元）</div>
            </el-row>

            <el-row>
              <div class="leftTitletStyle">可支配收入在本县农村居民人均以上的后扶人数（人）</div>
            </el-row>

            <el-row>
              <div class="leftTitletStyle">脱贫移民后扶人口（人）</div>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle firstTitletStyle">劳动力人数</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">总人数（人）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">其中劳动力输出人数（人）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <div class="leftTitletStyle">参与新型农村合作医疗（人）</div>
            </el-row>

            <el-row>
              <div class="leftTitletStyle">参与新型农村养老保险（人）</div>
            </el-row>
          </el-col>
        </el-row>

        <!-- 移民村组基本情况 -->
        <el-row>
          <el-col :span="4" class="title_view1">
            <div class="firstTitletStyle">移民村组基本情况</div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">有村卫生室的移民村</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">数量（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">占移民村比率（%）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">引水安全的移民村</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">数量（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">占移民村比率（%）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">引水安全的后扶移民人口</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">数量（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">占移民村比率（%）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">通公路的移民村</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">数量（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">占移民村比率（%）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">通机耕道的移民组</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">数量（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">占移民组比率（%）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">通电的移民组</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">数量（个）</div>
                </el-row>

                <el-row>
                  <div class="leftTitletStyle">占移民组比率（%）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">能接收电视的移民组</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">数量（个）</div>
                </el-row>

                <el-row>
                  <div class="leftTitletStyle">占移民组比率（%）</div>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div :style="boxWidth1" v-for="(item, index1) in listData" :key="item.id">
              <el-row v-if="type == 'add'">
                <div class="leftTitletStyle">
                  <el-link type="danger" @click="removeCol(index1)">删除</el-link>
                </div>
              </el-row>
              <el-row>
                <div class="contentStyle">
                  <el-cascader  :append-to-body="false"  
                    v-model="item.children[0].countyId"
                    size="mini"
                    :disabled="type == 'view'"
                    :options="levalRegion"
                    :props="treeProps"
                    @change="handleChange($event, index1)"
                    :show-all-levels="false"
                    placeholder="请选择"
                  >
                  </el-cascader>
                  <!-- <div class="box_view" v-else>
                    {{ col['county'] }}
                  </div> -->
                </div>
              </el-row>

              <el-row class="flex">
                <el-row style="width: 190px;" v-for="(col, index) in item.children" :key="col.id">
                  <template v-if="index <= 1">
                    <el-col :span="24">
                      <el-col>
                        <template v-for="key1 in Object.keys(col)">
                          <el-row v-if="key1 == 'year'">
                            <div class="contentStyle">
                              <div class="box_view">{{ col[key1] }}年度</div>
                            </div>
                          </el-row>

                          <!-- 后扶移民设计工程和行政区域情况 -->
                          <el-row v-if="key1 == 'hubLocations'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'onlyReservoirLocations'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationTowns'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationVillages'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationGroups'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <!-- 后扶移民基本情况 -->

                          <el-row v-if="key1 == 'postSupportPopulation'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'postSupportRealizedPopulation'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationFarmlandPerPerson'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationHousingAreaPerPerson'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationDisposableIncomePerPerson'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'aboveCountyAverageSupportPopulation'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'povertyAlleviationSupportPopulation'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'totalLaborForcePopulation'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'laborForceOutputPopulation'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'cooperativeMedicalParticipants'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'ruralPensionInsuranceParticipants'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <!-- 移民村组基本情况 -->

                          <el-row v-if="key1 == 'healthCentersRatioInVillages'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'healthCentersRatioInVillagesRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{
                                  calculateTheRate('healthCentersRatioInVillages', 'immigrationVillages', index1, index)
                                }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'drinkingWaterSafeSupportVillages'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                                oninput="value=value.replace(/[^\d]/g,'')"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'drinkingWaterSafeSupportVillagesRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{
                                  calculateTheRate(
                                    'drinkingWaterSafeSupportVillages',
                                    'immigrationVillages',
                                    index1,
                                    index
                                  )
                                }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'drinkingWaterSafeSupportPopulation'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'drinkingWaterSafeSupportPopulationRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{
                                  calculateTheRate(
                                    'drinkingWaterSafeSupportPopulation',
                                    'immigrationVillages',
                                    index1,
                                    index
                                  )
                                }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'roadsCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                oninput="value=value.replace(/[^\d]/g,'')"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationVillagesRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheRate('roadsCount', 'immigrationVillages', index1, index) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'machineFarmingRoadsCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationGroupsRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheRate('machineFarmingRoadsCount', 'immigrationGroups', index1, index) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'electrifiedImmigrationGroupsCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'electrifiedImmigrationGroupsRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{
                                  calculateTheRate(
                                    'electrifiedImmigrationGroupsCount',
                                    'immigrationGroups',
                                    index1,
                                    index
                                  )
                                }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'tvAccessibleImmigrationGroupsCount'">
                            <div class="contentStyle">
                              <el-input
                                v-if="type == 'add' || type == 'edit'"
                                size="mini"
                                type="number"
                                min="0"
                                v-model="col[key1]"
                                placeholder="请输入"
                              >
                              </el-input>

                              <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'tvAccessibleImmigrationGroupsRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{
                                  calculateTheRate(
                                    'tvAccessibleImmigrationGroupsCount',
                                    'immigrationGroups',
                                    index1,
                                    index
                                  )
                                }}
                              </div>
                            </div>
                          </el-row>
                        </template>
                      </el-col>
                    </el-col>
                  </template>

                  <template v-else>
                    <el-col :span="24">
                      <el-col>
                        <template v-for="key1 in Object.keys(col)">
                          <el-row v-if="key1 == 'year'">
                            <div class="contentStyle">
                              <!-- <div class="box_view">{{ col[key1] }}年度</div> -->
                              <div class="box_view">较上一年增加</div>
                            </div>
                          </el-row>

                          <!-- 后扶移民设计工程和行政区域情况 -->
                          <el-row v-if="key1 == 'hubLocations'">
                            <div class="contentStyle">
                              <div class="box_view">{{ calculateTheIncreace('hubLocations', index1) }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'onlyReservoirLocations'">
                            <div class="contentStyle">
                              <div class="box_view">{{ calculateTheIncreace('onlyReservoirLocations', index1) }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationTowns'">
                            <div class="contentStyle">
                              <div class="box_view">{{ calculateTheIncreace('immigrationTowns', index1) }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationVillages'">
                            <div class="contentStyle">
                              <div class="box_view">{{ calculateTheIncreace('immigrationVillages', index1) }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationGroups'">
                            <div class="contentStyle">
                              <div class="box_view">{{ calculateTheIncreace('immigrationGroups', index1) }}</div>
                            </div>
                          </el-row>

                          <!-- 后扶移民基本情况 -->

                          <el-row v-if="key1 == 'postSupportPopulation'">
                            <div class="contentStyle">
                              <div class="box_view">{{ calculateTheIncreace('postSupportPopulation', index1) }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'postSupportRealizedPopulation'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('postSupportRealizedPopulation', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationFarmlandPerPerson'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('immigrationFarmlandPerPerson', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationHousingAreaPerPerson'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('immigrationHousingAreaPerPerson', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationDisposableIncomePerPerson'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('immigrationDisposableIncomePerPerson', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'aboveCountyAverageSupportPopulation'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('aboveCountyAverageSupportPopulation', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'povertyAlleviationSupportPopulation'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('povertyAlleviationSupportPopulation', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'totalLaborForcePopulation'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('totalLaborForcePopulation', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'laborForceOutputPopulation'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('laborForceOutputPopulation', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'cooperativeMedicalParticipants'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('cooperativeMedicalParticipants', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'ruralPensionInsuranceParticipants'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('ruralPensionInsuranceParticipants', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <!-- 移民村组基本情况 -->

                          <el-row v-if="key1 == 'healthCentersRatioInVillages'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('healthCentersRatioInVillages', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'healthCentersRatioInVillagesRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{
                                  calculateTheIncreaceRate(
                                    'healthCentersRatioInVillages',
                                    'immigrationVillages',
                                    index1
                                  )
                                }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'drinkingWaterSafeSupportVillages'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('drinkingWaterSafeSupportVillages', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'drinkingWaterSafeSupportVillagesRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{
                                  calculateTheIncreaceRate(
                                    'drinkingWaterSafeSupportVillages',
                                    'immigrationVillages',
                                    index1
                                  )
                                }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'drinkingWaterSafeSupportPopulation'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('drinkingWaterSafeSupportPopulation', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'drinkingWaterSafeSupportPopulationRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{
                                  calculateTheIncreaceRate(
                                    'drinkingWaterSafeSupportPopulation',
                                    'immigrationVillages',
                                    index1
                                  )
                                }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'roadsCount'">
                            <div class="contentStyle">
                              <div class="box_view">{{ calculateTheIncreace('roadsCount', index1) }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationVillagesRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreaceRate('roadsCount', 'immigrationVillages', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'machineFarmingRoadsCount'">
                            <div class="contentStyle">
                              <div class="box_view">{{ calculateTheIncreace('machineFarmingRoadsCount', index1) }}</div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'immigrationGroupsRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreaceRate('machineFarmingRoadsCount', 'immigrationGroups', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'electrifiedImmigrationGroupsCount'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('electrifiedImmigrationGroupsCount', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'electrifiedImmigrationGroupsRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{
                                  calculateTheIncreaceRate(
                                    'electrifiedImmigrationGroupsCount',
                                    'immigrationGroups',
                                    index1
                                  )
                                }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'tvAccessibleImmigrationGroupsCount'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{ calculateTheIncreace('tvAccessibleImmigrationGroupsCount', index1) }}
                              </div>
                            </div>
                          </el-row>

                          <el-row v-if="key1 == 'tvAccessibleImmigrationGroupsRatio'">
                            <div class="contentStyle">
                              <div class="box_view">
                                {{
                                  calculateTheIncreaceRate(
                                    'tvAccessibleImmigrationGroupsCount',
                                    'immigrationGroups',
                                    index1
                                  )
                                }}
                              </div>
                            </div>
                          </el-row>
                        </template>
                      </el-col>
                    </el-col>
                  </template>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'

import { _getLevelRegion } from '@/api/keepHome'

import {
  _ProductionLivingAdd,
  _ProductionLivingInfo,
  _ProductionLivingEdit
} from '@/api/modular/postImmigrationService/monitorAndEvaluate/changeSituation.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      value: '2021年度',
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      countyId: null,
      levalRegion: [], //成都市
      treeProps: { label: 'name', value: 'id', children: 'childRegions', pid: 'pids', checkStrictly: true }
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 570 * this.listData.length
      return `width:${num}px;display:flex;`
    },
    boxWidth1() {
      let num = 190 * 3
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)

      this.$set(
        this.listData[index],
        'children',
        this.listData[index].children.map(ele => {
          return {
            ...ele,
            countyId: obj.id,
            county: obj.name,
            countyPids: obj.pids,
            orderNo: obj.orderNo
          }
        })
      )
      console.log(this.listData[index], index)
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    calculateTheIncreace(name, index) {
      let ele = this.listData[index].children
      return (ele[1][name] * 1000 - ele[0][name] * 1000) / 1000
    },
    calculateTheRate(name1, name2, index1, index) {
      let ele = this.listData[index1].children[index]

      let sum = 0
      // 定义一个变量保存上一次的sum值
      let prevSum = 0

      // 当前sum值计算
      let currentSum = (Number(ele[name1]) / Number(ele[name2])) * 100
      // 如果当前sum值为NaN或Infinity，则使用上一次的sum值
      if (Number.isNaN(currentSum) || isFinite(currentSum)) {
        sum = Number.isNaN(currentSum) ? 0 : currentSum
      } else {
        sum = prevSum
      }
      // 更新上一次的sum值
      prevSum = sum

      return Math.round((prevSum.toFixed(4) * 100) / 100) + '%'
    },
    calculateTheIncreaceRate(name1, name2, index1) {
      let ele1 = this.listData[index1].children[0]
      let ele2 = this.listData[index1].children[1]

      let num1 = ele2[name1] - ele1[name1]
      let num2 = ele2[name2] - ele1[name2]

      let sum = 0
      // 定义一个变量保存上一次的sum值
      let prevSum = 0

      // 当前sum值计算
      let currentSum = (num1 / num2) * 100
      // 如果当前sum值为NaN或Infinity，则使用上一次的sum值
      if (Number.isNaN(currentSum) || isFinite(currentSum)) {
        sum = Number.isNaN(currentSum) ? 0 : currentSum
      } else {
        sum = prevSum
      }
      // 更新上一次的sum值
      prevSum = sum

      return Math.round((prevSum.toFixed(4) * 100) / 100) + '%'
    },
    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row
      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },

    editCols() {
      this.type = 'edit'
      this.title = '编辑'
    },

    btnClose() {
      this.listData = []
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let children = [
            {
              year: this.searchObj.year - 1,
              countyId: null,
              county: '',
              countyPids: '',
              orderNo: null,
              totalReservoirs: null,
              hubLocations: null,
              onlyReservoirLocations: null,
              immigrationTowns: null,
              immigrationVillages: null,
              immigrationGroups: null,
              postSupportPopulation: null,
              postSupportRealizedPopulation: null,
              immigrationFarmlandPerPerson: null,
              immigrationHousingAreaPerPerson: null,
              immigrationDisposableIncomePerPerson: null,
              aboveCountyAverageSupportPopulation: null,
              povertyAlleviationSupportPopulation: null,
              totalLaborForcePopulation: null,
              laborForceOutputPopulation: null,
              cooperativeMedicalParticipants: null,
              ruralPensionInsuranceParticipants: null,
              healthCentersRatioInVillages: null,
              healthCentersRatioInVillagesRatio: null,
              drinkingWaterSafeSupportVillages: null,
              drinkingWaterSafeSupportVillagesRatio: null,
              drinkingWaterSafeSupportPopulation: null,
              drinkingWaterSafeSupportPopulationRatio: null,
              roadsCount: null,
              immigrationVillagesRatio: null,
              machineFarmingRoadsCount: null,
              immigrationGroupsRatio: null,
              electrifiedImmigrationGroupsCount: null,
              electrifiedImmigrationGroupsRatio: null,
              tvAccessibleImmigrationGroupsCount: null,
              tvAccessibleImmigrationGroupsRatio: null
            },
            {
              year: this.searchObj.year,
              countyId: null,
              county: '',
              countyPids: '',
              orderNo: null,
              totalReservoirs: null,
              hubLocations: null,
              onlyReservoirLocations: null,
              immigrationTowns: null,
              immigrationVillages: null,
              immigrationGroups: null,
              postSupportPopulation: null,
              postSupportRealizedPopulation: null,
              immigrationFarmlandPerPerson: null,
              immigrationHousingAreaPerPerson: null,
              immigrationDisposableIncomePerPerson: null,
              aboveCountyAverageSupportPopulation: null,
              povertyAlleviationSupportPopulation: null,
              totalLaborForcePopulation: null,
              laborForceOutputPopulation: null,
              cooperativeMedicalParticipants: null,
              ruralPensionInsuranceParticipants: null,
              healthCentersRatioInVillages: null,
              healthCentersRatioInVillagesRatio: null,
              drinkingWaterSafeSupportVillages: null,
              drinkingWaterSafeSupportVillagesRatio: null,
              drinkingWaterSafeSupportPopulation: null,
              drinkingWaterSafeSupportPopulationRatio: null,
              roadsCount: null,
              immigrationVillagesRatio: null,
              machineFarmingRoadsCount: null,
              immigrationGroupsRatio: null,
              electrifiedImmigrationGroupsCount: null,
              electrifiedImmigrationGroupsRatio: null,
              tvAccessibleImmigrationGroupsCount: null,
              tvAccessibleImmigrationGroupsRatio: null
            },
            {
              year: this.searchObj.year,
              countyId: null,
              county: '',
              countyPids: '',
              orderNo: null,
              totalReservoirs: null,
              hubLocations: null,
              onlyReservoirLocations: null,
              immigrationTowns: null,
              immigrationVillages: null,
              immigrationGroups: null,
              postSupportPopulation: null,
              postSupportRealizedPopulation: null,
              immigrationFarmlandPerPerson: null,
              immigrationHousingAreaPerPerson: null,
              immigrationDisposableIncomePerPerson: null,
              aboveCountyAverageSupportPopulation: null,
              povertyAlleviationSupportPopulation: null,
              totalLaborForcePopulation: null,
              laborForceOutputPopulation: null,
              cooperativeMedicalParticipants: null,
              ruralPensionInsuranceParticipants: null,
              healthCentersRatioInVillages: null,
              healthCentersRatioInVillagesRatio: null,
              drinkingWaterSafeSupportVillages: null,
              drinkingWaterSafeSupportVillagesRatio: null,
              drinkingWaterSafeSupportPopulation: null,
              drinkingWaterSafeSupportPopulationRatio: null,
              roadsCount: null,
              immigrationVillagesRatio: null,
              machineFarmingRoadsCount: null,
              immigrationGroupsRatio: null,
              electrifiedImmigrationGroupsCount: null,
              electrifiedImmigrationGroupsRatio: null,
              tvAccessibleImmigrationGroupsCount: null,
              tvAccessibleImmigrationGroupsRatio: null
            }
          ]

          let params = {
            id: Math.random(),
            children
          }
          this.listData.splice(this.listData.length, 0, params)
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    submitForm() {
      console.log(this.listData, '===submitForm===')

      let listData = this.listData.map(ele => {
        let obj1 = ele.children[0]
        let obj2 = ele.children[1]
        return {
          productionLivingBefore: {
            id: obj1.id,
            year: obj1.year,
            countyId: obj1.countyId,
            county: obj1.county,
            countyPids: obj1.countyPids,
            orderNo: obj1.orderNo,
            totalReservoirs: obj1.totalReservoirs,
            hubLocations: obj1.hubLocations,
            onlyReservoirLocations: obj1.onlyReservoirLocations,
            immigrationTowns: obj1.immigrationTowns,
            immigrationVillages: obj1.immigrationVillages,
            immigrationGroups: obj1.immigrationGroups,
            postSupportPopulation: obj1.postSupportPopulation,
            postSupportRealizedPopulation: obj1.postSupportRealizedPopulation,
            immigrationFarmlandPerPerson: obj1.immigrationFarmlandPerPerson,
            immigrationHousingAreaPerPerson: obj1.immigrationHousingAreaPerPerson,
            immigrationDisposableIncomePerPerson: obj1.immigrationDisposableIncomePerPerson,
            aboveCountyAverageSupportPopulation: obj1.aboveCountyAverageSupportPopulation,
            povertyAlleviationSupportPopulation: obj1.povertyAlleviationSupportPopulation,
            totalLaborForcePopulation: obj1.totalLaborForcePopulation,
            laborForceOutputPopulation: obj1.laborForceOutputPopulation,
            cooperativeMedicalParticipants: obj1.cooperativeMedicalParticipants,
            ruralPensionInsuranceParticipants: obj1.ruralPensionInsuranceParticipants,
            healthCentersRatioInVillages: obj1.healthCentersRatioInVillages,
            drinkingWaterSafeSupportVillages: obj1.drinkingWaterSafeSupportVillages,
            drinkingWaterSafeSupportPopulation: obj1.drinkingWaterSafeSupportPopulation,
            roadsCount: obj1.roadsCount,
            machineFarmingRoadsCount: obj1.machineFarmingRoadsCount,
            electrifiedImmigrationGroupsCount: obj1.electrifiedImmigrationGroupsCount,
            tvAccessibleImmigrationGroupsCount: obj1.tvAccessibleImmigrationGroupsCount
          },
          productionLiving: {
            id: obj2.id,
            year: obj2.year,
            countyId: obj2.countyId,
            county: obj2.county,
            countyPids: obj2.countyPids,
            orderNo: obj2.orderNo,
            totalReservoirs: obj2.totalReservoirs,
            hubLocations: obj2.hubLocations,
            onlyReservoirLocations: obj2.onlyReservoirLocations,
            immigrationTowns: obj2.immigrationTowns,
            immigrationVillages: obj2.immigrationVillages,
            immigrationGroups: obj2.immigrationGroups,
            postSupportPopulation: obj2.postSupportPopulation,
            postSupportRealizedPopulation: obj2.postSupportRealizedPopulation,
            immigrationFarmlandPerPerson: obj2.immigrationFarmlandPerPerson,
            immigrationHousingAreaPerPerson: obj2.immigrationHousingAreaPerPerson,
            immigrationDisposableIncomePerPerson: obj2.immigrationDisposableIncomePerPerson,
            aboveCountyAverageSupportPopulation: obj2.aboveCountyAverageSupportPopulation,
            povertyAlleviationSupportPopulation: obj2.povertyAlleviationSupportPopulation,
            totalLaborForcePopulation: obj2.totalLaborForcePopulation,
            laborForceOutputPopulation: obj2.laborForceOutputPopulation,
            cooperativeMedicalParticipants: obj2.cooperativeMedicalParticipants,
            ruralPensionInsuranceParticipants: obj2.ruralPensionInsuranceParticipants,
            healthCentersRatioInVillages: obj2.healthCentersRatioInVillages,
            drinkingWaterSafeSupportVillages: obj2.drinkingWaterSafeSupportVillages,
            drinkingWaterSafeSupportPopulation: obj2.drinkingWaterSafeSupportPopulation,
            roadsCount: obj2.roadsCount,
            machineFarmingRoadsCount: obj2.machineFarmingRoadsCount,
            electrifiedImmigrationGroupsCount: obj2.electrifiedImmigrationGroupsCount,
            tvAccessibleImmigrationGroupsCount: obj2.tvAccessibleImmigrationGroupsCount
          }
        }
      })

      console.log(listData, '====提交的数据格式========')

      // return

      let flag = listData.every(ele => {
        return ele.productionLivingBefore.countyId && ele.productionLiving.countyId
      })

      switch (this.type) {
        case 'add':
          if (flag) {
            _ProductionLivingAdd(listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
        case 'edit':
          if (flag) {
            _ProductionLivingEdit(listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
      }
    },
    getBasicInfo() {
      let params = {
        id: this.row
      }

      _ProductionLivingInfo(params).then(res => {
        if (res.code == 200) {
          let obj = res.data

          let obj1 = {
            id: Math.random(),
            children: [obj.productionLivingBefore, obj.productionLiving, obj.productionLivingIncrease]
          }

          console.log(obj1, '===========obj1=========')

          this.countyId = obj.productionLivingBefore.countyId

          this.listData = [obj1]

          console.log(this.listData, '===this.listData===')
        }
      })
    },
    formateResData(arr) {
      return arr.map(ele => {
        return {
          year: ele.year, //年份
          countyId: ele.countyId, //县（市区）
          county: ele.county, //县（市区）
          locationTotal: ele.locationTotal,
          hubLocation: ele.hubLocation, //枢纽工程所在地（座）
          warehouseLocation: ele.warehouseLocation, //仅库区所在地（座）
          immigrantCounties: ele.immigrantCounties, //移民涉及县（个）
          immigrantTowns: ele.immigrantTowns, //移民乡（个）
          immigrantCommunitys: ele.immigrantCommunitys, //移民村（个）
          immigrantGroups: ele.immigrantGroups, //移民组（个）
          directProjectTotal: 0,
          directAid: ele.directAid, //直发直补（人）
          projectSupport: ele.projectSupport, //项目扶持（人）
          perCapitaIncome: ele.perCapitaIncome, //移民人均可支配收入（元）
          povertyPopulation: ele.povertyPopulation, //脱贫人口（人)
          civilServants: ele.civilServants, //公务员编制（人）
          publicInstitutionStaff: ele.publicInstitutionStaff, //事业单位编制（人）
          cityLevelStaff: ele.cityLevelStaff, //市级（人）
          countyLevelStaff: ele.countyLevelStaff, //县级（人）
          postAidPlanningReview: ele.postAidPlanningReview, //后扶规划编审（个）
          developmentPlanningActualReview: ele.developmentPlanningActualReview, //发展规划实际编审（个）
          projectAllIncomeTotal: 0, //后期扶持合计（收入）
          projectAllExpenditureTotal: 0, //后期扶持合计（支出）
          directProjectIncomeTotal: 0, //600元小计（收入）
          directProjectExpenditureTotal: 0, //600元小计（支出）
          directAidIncome: ele.directAidIncome, //直发直补收入（万元）
          directAidExpenditure: ele.directAidExpenditure, //直发直补支出（万元）
          projectSupportIncome: ele.projectSupportIncome, //项目扶持收入（万元）
          projectSupportExpenditure: ele.projectSupportExpenditure, //项目扶持支出（万元）
          projectFundsIncome: ele.projectFundsIncome, //项目资金收入（万元）
          projectFundsExpenditure: ele.projectFundsExpenditure, //项目资金支出（万元）
          otherFundsIncome: ele.otherFundsIncome, //其他资金（专项工作经费）收入（万元）
          otherFundsExpenditure: ele.otherFundsExpenditure, //其他资金（专项工作经费）支出（万元）
          countyPids: ele.countyPids,
          id: ele.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
.clearFloat > div:last-child {
  // border-right: 1px solid red;
}
</style>
